<template>
	<div class="">
		<div class="card card-custom" style="height: 100%;">
			<div class="px-5 py-3">
				<b-tabs active-nav-item-class="tab-label-dark" content-class="mt-7 px-4">
					<b-tab>
						<template #title>
							<div class="align-items-start flex-column mb-1">
								<h3 class="tab-label card-title font-weight-bolder mb-0">Site Profile</h3>
								<span class="text-muted font-weight-bold font-size-sm">Change site settings</span>
							</div>
						</template>
						<form class="form">
							<div class="form-group row">
								<label class="col-xl-3 col-lg-3 col-form-label">Site Name</label>
								<div class="col-lg-9 col-xl-6">
									<input class="form-control form-control-lg form-control-solid" type="text" v-model="profileForm.name" />
									<span v-show="!validateSiteName" class="form-text hasError">Name is required!</span>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-xl-3 col-lg-3 col-form-label">Icon</label>
								<div class="col-lg-9 col-xl-6">
									<select class="form-control form-control-lg form-control-solid" v-model="profileForm.icon">
										<option :value="null">None Selected</option>
										<option v-for="(icon, i) in icons" :value="icon" :key="i">{{ icon }}</option>
									</select>
								</div>
							</div>
							<div class="separator separator-dashed my-5"></div>
							<div class="form-group row mb-2">
								<label class="col-xl-3 col-lg-3 col-form-label">Site Notes</label>
								<div class="col-lg-9 col-xl-6">
									<div class="form-group">
										<label class="form__label label-heading" for="textarea-default">
											<div>Visible to Staff, NOC and Admin user roles only:</div>
										</label>
										<b-form-textarea class="form__input" id="textarea-sidenote" v-model="profileForm.notes" rows="6" max-rows="6"></b-form-textarea>
									</div>
								</div>
							</div>
							<div class="row justify-content-center" style="height: 40px;">
								<button v-if="profileUpdated" type="reset" class="btn btn-secondary mr-5" @click.prevent="cancel">Cancel</button>
								<button
									v-if="profileUpdated"
									type="submit"
									:disabled="!validateSiteName || !profileUpdated"
									:class="[{ 'spinner spinner-light spinner-right': submitting }, { invalid: !validateSiteName }, 'btn', 'btn-success']"
									@click.prevent="saveSite"
								>
									Save Changes
								</button>
							</div>
						</form>
					</b-tab>
					<b-tab v-if="adminSite.data.shownocalerts">
						<template #title>
							<div class="align-items-start flex-column mb-1">
								<h3 class="tab-label card-title font-weight-bolder mb-0">NOC Alerts</h3>
								<span class="text-muted font-weight-bold font-size-sm ">Add/Remove</span>
							</div>
						</template>
						<div class="row">
							<span> Emails will be sent when the iDirect value drops below threshold for the <i>Alert Wait</i> time. </span>
						</div>
						<div class="row mt-5">
							<b-form class="col-12 p-0" @submit="saveNocAlerts">
								<div class="row">
									<div class="col-2 d-flex align-items-center">
										<strong>NOC Emails</strong>
									</div>
									<div class="col-sm-1">
										<span class="switch switch-sm">
											<label>
												<input type="checkbox" v-model="email_noc" true-value="1" false-value="0" name="email_noc" />
												<span></span>
											</label>
										</span>
									</div>
									<div class="d-flex align-items-center">
										<span v-if="email_noc == 1"><strong>On:</strong> emails will be sent to <strong>noc@itcglobal.com</strong></span>
										<span v-else><strong>Off:</strong> emails will <strong>NOT</strong> be sent to <strong>noc@itcglobal.com</strong></span>
									</div>
								</div>
								<div class="row mt-7">
									<div class="col-2 d-flex align-items-center">
										<strong>SNR</strong>
										<span v-if="nocAlerts.snr.muted == 1" class="muted" @click="showMuteModal(nocAlerts.snr)">MUTED</span>
									</div>
									<div class="col-3">
										<b-form-group label="Threshold">
											<b-form-select v-model="nocAlerts.snr.value" :options="snrThresholds"></b-form-select>
										</b-form-group>
									</div>
									<div class="col-3">
										<b-form-group label="Alert Wait">
											<b-form-select v-model="nocAlerts.snr.alert_wait" :options="alertWait"></b-form-select>
										</b-form-group>
									</div>
									<div class="col-3">
										<b-form-group label="Additional Alerts">
											<b-form-select v-model="nocAlerts.snr.alert_again_wait" :options="alertAgainWait"></b-form-select>
										</b-form-group>
									</div>
									<div class="col-1 d-flex align-items-center clear-alert" @click="clearNocAlert('snr')">Clear</div>
								</div>
								<div class="row">
									<div class="col-2 d-flex align-items-center">
										<strong>MODCOD</strong>
										<span v-if="nocAlerts.modcod.muted == 1" class="muted" @click="showMuteModal(nocAlerts.modcod)">MUTED</span>
									</div>
									<div class="col-3">
										<b-form-select v-model="nocAlerts.modcod.value" :options="modcodOptions"></b-form-select>
									</div>
									<div class="col-3">
										<b-form-select v-model="nocAlerts.modcod.alert_wait" :options="alertWait"></b-form-select>
									</div>
									<div class="col-3">
										<b-form-select v-model="nocAlerts.modcod.alert_again_wait" :options="alertAgainWait"></b-form-select>
									</div>
									<div class="col-1 d-flex align-items-center clear-alert" @click="clearNocAlert('modcod')">Clear</div>
								</div>
								<div class="row mt-5">
									<div class="col-12 response text-center">{{ response }}</div>
								</div>
								<div class="row">
									<div class="col-2 d-flex align-items-center">
										<strong>Email</strong>
										<span v-if="nocAlerts.snr.muted == 1" class="muted" @click="showMuteModal(nocAlerts.snr)">MUTED</span>
									</div>
									<div class="col-9">
										<b-form-group label="Emails to use for NOC Alerts">
											<b-form-input v-model="emails" placeholder="enter email addresses separated by a semicolon..." class="col-12"></b-form-input>
										</b-form-group>
									</div>
									<div style="margin: -15px 0 0 150px" v-if="emails">*Add additional emails separated by a semicolon</div>
								</div>
								<div class="row mt-5"></div>
								<div class="row mt-5 mb-5">
									<div class="col-12 d-flex justify-content-center">
										<b-button type="reset" variant="secondary" class="mr-2 px-13">Cancel</b-button>
										<b-button type="submit" variant="success" :class="[{ 'spinner spinner-white spinner-right': submittingNocAlerts }, 'px-15']">
											Save
										</b-button>
									</div>
								</div>
							</b-form>
						</div>
					</b-tab>
				</b-tabs>
			</div>
			<!--end::Form-->
		</div>
		<!--end::Card-->
		<MuteModal :alert="selectedAlert" @hidden="selectedAlert = null" @saved="showResponse('NOC Alert successfully saved')" />
	</div>
	<!--end::Content-->
</template>

<script>
import { mapGetters } from 'vuex';
import { REFRESH_ADMINEDIT_SITE, UPDATE_SITE_PROFILE } from '@/core/services/store/admin.module';
import { MODCODS } from '@/helpers';
// import { SET_READY_STATE } from '@/core/services/store/ready.module';

// import swMixin from '@/core/services/mixins/serviceworker.mixin';
// import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';

export default {
	name: 'SiteProfile',
	data() {
		return {
			icons: ['dish', 'oilrig', 'ship', 'yacht', 'cruiseship', 'mine', 'camp', 'office', 'airplane', 'aid', 'cloud', 'datacenter'],
			loaded: false,
			submitting: false,
			profileForm: {
				name: '',
				icon: null,
				notes: null,
			},
			email_noc: 1,
			emails: '',
			nocAlerts: {
				snr: { value: null, alert_wait: null, alert_again_wait: null, muted: null },
				modcod: { value: null, alert_wait: null, alert_again_wait: null, muted: null },
			},
			snrThresholds: [2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5],
			alertWait: [
				{ value: '.5', text: '30 minutes' },
				{ value: '1', text: '1 hour' },
				{ value: '2', text: '2 hours' },
				{ value: '3', text: '3 hours' },
				{ value: '6', text: '6 hours' },
			],
			alertAgainWait: [
				{ value: '1', text: '1 hour' },
				{ value: '6', text: '6 hours' },
				{ value: '24', text: '24 hours' },
				{ value: '48', text: '48 hours' },
				{ value: '72', text: '72 hours' },
			],
			submittingNocAlerts: false,
			selectedAlert: null,
			response: null,
		};
	},
	components: {
		MuteModal: () => import('@/view/content/widgets/admin/editsite/NOCAlertsModal.vue'),
	},
	computed: {
		...mapGetters(['adminSite', 'adminSiteLoading']),
		modcodOptions() {
			return MODCODS.map((m, i) => {
				return { value: i + 1, text: m.name || '' };
			});
		},
		nocAlertsApiFormatted() {
			//API is written to take values in this format on UPDATE and convert back to db columns
			return {
				SiteId: this.$route.params.id,
				mcalert: this.nocAlerts.modcod.value && parseInt(this.nocAlerts.modcod.value),
				mcalertwait: this.nocAlerts.modcod.alert_wait,
				mcalertagainwait: this.nocAlerts.modcod.alert_again_wait,
				snralert: this.nocAlerts.snr.value,
				snralertwait: this.nocAlerts.snr.alert_wait,
				snralertagainwait: this.nocAlerts.snr.alert_again_wait,
				email_noc: this.email_noc,
				emails: this.emails,
			};
		},
		validateSiteName() {
			if (this.profileForm.name.length > 1) {
				return true;
			} else {
				return false;
			}
		},
		profileUpdated() {
			if (
				this.profileForm.name !== this.adminSite.name ||
				this.profileForm.icon !== this.adminSite.icon ||
				this.profileForm.notes !== this.adminSite.notes
			) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		saveSite() {
			this.submitting = true;
			this.$http.put(`/adminsite/${this.adminSite.id}`, this.profileForm).then(resp => {
				if (resp.status == 200) {
					this.$store.commit(UPDATE_SITE_PROFILE, this.profileForm);
					this.submitting = false;
				}
			});
		},
		saveNocAlerts(e) {
			this.submittingNocAlerts = true;
			e.preventDefault();
			this.$http.post('editnocalerts', this.nocAlertsApiFormatted).then(resp => {
				this.showResponse(resp.data.data.msg);
				this.$store.dispatch(REFRESH_ADMINEDIT_SITE);
				this.submittingNocAlerts = false;
			});
		},
		showResponse(msg) {
			this.response = msg;
			setTimeout(() => {
				this.response = null;
			}, 5000);
		},
		clearNocAlert(alert) {
			this.nocAlerts[alert].value = undefined;
			this.nocAlerts[alert].alert_wait = undefined;
			this.nocAlerts[alert].alert_again_wait = undefined;
		},
		showMuteModal(alert) {
			this.selectedAlert = alert;
			this.$bvModal.show('muteModal');
			this.$store.dispatch(REFRESH_ADMINEDIT_SITE);
		},
		cancel() {
			this.profileForm.name = this.adminSite.name;
			this.profileForm.notes = this.adminSite.notes;
			if (this.adminSite.icon === '') {
				this.profileForm.icon = 'None Selected';
			} else {
				this.profileForm.icon = this.adminSite.icon;
			}
			this.profileUpdated = false;
		},
		initializeForm() {
			this.profileForm.name = this.adminSite.name;
			this.profileForm.icon = this.adminSite.icon;
			this.profileForm.notes = this.adminSite.notes;
			if (this.adminSite.data.nocalerts && this.adminSite.data.nocalerts.length > 0) {
				this.email_noc = this.adminSite.data.nocalerts[0].email_noc;
				if (this.adminSite.data.nocalerts[0].emails) {
					this.emails = this.adminSite.data.nocalerts[0].emails;
				}
				this.adminSite.data.nocalerts.forEach(alert => {
					this.nocAlerts[alert.name] = { ...alert };
				});
			}
		},
	},
	watch: {
		adminSiteLoading(loading) {
			if (!loading) {
				this.initializeForm();
			}
		},
	},
	mounted() {
		this.initializeForm();
	},
};
</script>

<style scoped>
.tab-label {
	font-weight: 400;
	font-size: 1.25rem;
}
.tab-label-dark h3 {
	color: rgb(1, 1, 51);
}
.response {
	color: #1bc5bd;
	min-height: 16px;
}
.clear-alert:hover {
	color: #f64e60;
	cursor: pointer;
}
.muted:hover {
	background: #f64e60;
	cursor: pointer;
}
.muted {
	padding: 5px;
	background: #3699ff;
	border-radius: 3px;
	color: white;
	margin-left: 3px;
}
.hasError {
	color: red;
}
.invalid {
	cursor: not-allowed;
}
</style>
